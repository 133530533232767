import React from "react";
import { useToast } from "@chakra-ui/react";
import { useRouter } from "next/dist/client/router";
import { useRequest } from "../contexts/request";
import getVideoId from "get-video-id";
import {  t } from "@lingui/macro";
import { getTiktokVideoId, getInstagramVideoId } from "../utils";

const useDonation = () => {
  const { post } = useRequest();
  const router = useRouter();

  const toast = useToast();

  const createDonation = async (
    streamerId,
    {
      from,
      email,
      phone,
      giphy,
      mediaType,
      yt,
      soundboard,
      ytStart,
      tiktok,
      reels,
      voice_note,
      ...formValues
    }
  ) => {
    const { payment_type } = formValues;
    let options = {};
    if (mediaType === "gif" && giphy) {
      options.media = {
        type: "giphy",
        id: giphy.id,
      };
    } else if (mediaType === "yt" && yt) {
      const { id } = getVideoId(yt);

      options.media = {
        type: "yt",
        id,
        start: ytStart,
      };
     } else if (mediaType === "tiktok" && tiktok) {
      const { id } = await getTiktokVideoId(tiktok);

      options.media = {
        type: "tiktok",
        id,
      };
    } else if (mediaType === "reels" && reels) {
      const  id  = getInstagramVideoId(reels);
      options.media = {
        type: "reels",
        id,
      };
    } else if (mediaType === "voice_note" && voice_note) {
      options.media = {
        type: "voice_note",
        src: voice_note,
      };
    } else if (mediaType === "sb" && soundboard) {
      options.media = {
        type: "sb",
        id: soundboard.id,
      };
    }
    try {
      const { json } = await post(`donations/${streamerId}`, {
        json: {
          ...formValues,
          ...options,
          customer_info: { first_name: from, email, phone },
        },
        // set timeout to 1 minute, because sometimes we need download ig reels first
        timeout: 60000
      });

      if (payment_type === "qris") {
        const {
          data: { id },
        } = json;
        router.push({
          pathname: `/qris/${id}`,
        });
        return;
      }

      // Tell user to open ovo
      if (payment_type === "ovo") {
        router.push("/open-ovo");
        return;
      }

      // If linkaja, gopay, or dana go to url
      else if (
        [
          "linkaja",
          "dana",
          "gopay",
          "shopeepay",
          "gcash",
          "paymaya",
          "grabpay",
        ].includes(payment_type)
      ) {
        const {
          data: { redirect_url: redirectUrl },
        } = json;

        if (redirectUrl) {
          window.location.assign(redirectUrl);
          return;
        }
      }

      throw new Error("Something is not right with our payment gateway");
    } catch (error) {
      let d = "";
      if (!error.response) {
        d =
          "Ada yang salah dari sistem kami / penyedia layanan pembayaran. Cobalah beberapa saat lagi.";
      } else {
        if (error.response.status === 401) {
          toast({
            title: "Transaksi Gagal",
            description: "Silahkan login terlebih dahulu.",
            status: "warning",
            duration: 10000,
            isClosable: true,
          });
          return;
        }
        switch (error.response.json.message) {
          // OVO error message
          case "PHONE_NUMBER_NOT_REGISTERED":
            d = `Nomor ${phone} tidak terdaftar di sistem pembayaran OVO. Silahkan mencoba lagi dengan nomor yang lain.`;
            break;
          case "USER_DID_NOT_AUTHORIZE_THE_PAYMENT":
            d =
              "User tidak mengkonfirmasi transaksi dalam batas waktu yang diberikan. Silahkan mencoba lagi.";
            break;
          case "USER_DECLINED_THE_TRANSACTION":
            d = "User membatalkan transaksi.";
            break;
          default:
            d =
              "Ada yang salah dari sistem kami / penyedia pembayaran. Cobalah beberapa saat lagi.";
        }
      }

      toast({
        title: "Transaksi Gagal",
        description: d,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
      throw new Error(d);
    }
  };
  const calculateAmountToPay = async (
    streamerName,
    { from, email, phone, ...formValues },
  ) => {
    try {
      const { json } = await post(`donations/${streamerName}/calculate_pg_amount`, {
        json: {
          ...formValues,
          customer_info: { first_name: from, email, phone },
        },
      });
      return json.data.amount_to_pay
    } catch (error) {
      toast({
        title: "",
        description: t`Sedang terjadi error, coba pilih metode pembayaran kembali!`,
        status: "error",
        duration: 10000,
        isClosable: true,
      });
    }
  };
  return { createDonation, calculateAmountToPay };
};
export default useDonation;
