import React from "react";
import { Flex, Stack, Wrap, WrapItem } from "@chakra-ui/react";

import Button from "../../../components/Button";
import { GifSelector } from "./GifSelector";
import { YTSelector } from "./YTSelector";
import { P } from "../../../components/Type";
import { TiktokSelector } from "./TiktokSelector";
import { ReelsSelector } from "./ReelsSelector";
import { VoiceNoteSelector } from "./VoiceNoteSelector";

const MediaSelectorTab = ({ overlay, setFieldValue, children }) => {
  const [selected, setSelected] = React.useState(null);
  const _children = React.Children.toArray(children);
  const { media, mediashare, youtube, tiktok, reels, voice_note } = overlay;
  return (
    <Flex direction="column">
      <Wrap columns={5} spacing={"0.5em"} paddingBottom={3}>
        {media ? (
          <WrapItem flexGrow={1}>
            <Button
              marginBottom={0}
              flexGrow={1}
              data-testid="giphy-button"
              pressed={0 === selected}
              backgroundColor="saweria-green.normal"
              type="button"
              label={"GIF"}
              onClick={() => {
                if (selected === 0) {
                  setSelected(null);
                  setFieldValue("mediaType", null);
                  return;
                }
                setSelected(0);
                setFieldValue("mediaType", "gif");
              }}
            />
          </WrapItem>
        ) : null}
        {mediashare && youtube != false ? (
          <WrapItem flexGrow={1}>
            <Button
              flexGrow={1}
              data-testid="yt-button"
              pressed={1 === selected}
              marginBottom={0}
              type="button"
              backgroundColor="#c4302b"
              color="white"
              label={"YouTube"}
              onClick={() => {
                if (selected === 1) {
                  setSelected(null);
                  setFieldValue("mediaType", null);
                  return;
                }
                setSelected(1);
                setFieldValue("mediaType", "yt");
              }}
            />
          </WrapItem>
        ) : null}
        {mediashare && tiktok ? (
          <WrapItem flexGrow={1}>
            <Button
              color="white"
              flexGrow={1}
              data-testid="tiktok-button"
              pressed={2 === selected}
              type="button"
              backgroundColor="#080808"
              label={"Tiktok"}
              onClick={() => {
                if (selected === 2) {
                  setSelected(null);
                  setFieldValue("mediaType", null);
                  return;
                }
                setSelected(2);
                setFieldValue("mediaType", "tiktok");
              }}
            />
          </WrapItem>
        ) : null}
        {mediashare && reels ? (
          <WrapItem flexGrow={1}>
            <Button
              color="black"
              flexGrow={1}
              data-testid="reels-button"
              pressed={3 === selected}
              type="button"
              label={"Reels"}
              onClick={() => {
                if (selected === 3) {
                  setSelected(null);
                  setFieldValue("mediaType", null);
                  return;
                }
                setSelected(3);
                setFieldValue("mediaType", "reels");
              }}
            />
          </WrapItem>
        ) : null}
        {mediashare && voice_note? (
          <WrapItem flexGrow={1}>
            <Button
              color="black"
              flexGrow={1}
              data-testid="voice_note-button"
              pressed={4 === selected}
              type="button"
              backgroundColor="saweria-yellow.normal"
              label={"Voice Note"}
              onClick={() => {
                if (selected === 4) {
                  setSelected(null);
                  setFieldValue("mediaType", null);
                  return;
                }
                setSelected(4);
                setFieldValue("mediaType", "voice_note");
              }}
            />
          </WrapItem>
        ) : null}
      </Wrap>
      <Flex paddingBottom={2} direction="column">
        {_children[selected]}
      </Flex>
    </Flex>
  );
};

const MediaSelector = ({
  plus,
  overlay,
  values,
  setFieldValue,
  setFieldError,
  currency,
}) => {
  if (!(plus && (overlay.media || overlay.mediashare))) {
    return null;
  }

  return (
    <>
      <P>Media:</P>
      <MediaSelectorTab
        setFieldValue={setFieldValue}
        overlay={overlay}
      >
        <GifSelector overlay={overlay} currency={currency} />
        <YTSelector
          currency={currency}
          overlay={overlay}
          values={values}
          setFieldError={setFieldError}
        />
        <TiktokSelector
          currency={currency}
          overlay={overlay}
          values={values}
          setFieldError={setFieldError}
        />
        <ReelsSelector
          currency={currency}
          overlay={overlay}
          values={values}
          setFieldError={setFieldError}
        />
        <VoiceNoteSelector
          currency={currency}
          overlay={overlay}
          values={values}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />
      </MediaSelectorTab>
    </>
  );
};

export { MediaSelector };
